import React from 'react';

function Input(props) {
  const { type, value, onFocus, onBlur, placeholder } = props;

  const onChange = (e) => {
    const { set } = props;
    set(e.currentTarget.value);
  }

  return (
    <div className='vault-input'>
      <input onFocus={onFocus} onBlur={onBlur}
        onChange={onChange} value={value} type={type}
        placeholder={placeholder}
      />
    </div>
  )
}

export default Input;