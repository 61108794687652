export default [
    {value: "Tarantino Foods", label: "Tarantino Foods"},
    {value: "F&A Food Sales Inc", label: "F&A Food Sales Inc"},
    {value: "Plaza Food Systems", label: "Plaza Food Systems"},
    {value: "Farmer Brothers Co", label: "Farmer Brothers Co"},
    {value: "Atlas Wholesale", label: "Atlas Wholesale"},
    {value: "BakeMark (PA)", label: "BakeMark (PA)"},
    {value: "Restaurant Depot", label: "Restaurant Depot"},
    {value: "South Jersey Paper Products Co., Inc.", label: "South Jersey Paper Products Co., Inc."},
    {value: "Ginsberg's Institutional Foods", label: "Ginsberg's Institutional Foods"},
    {value: "CA Curtze Co.", label: "CA Curtze Co."},
    {value: "McDonald Wholesale Co", label: "McDonald Wholesale Co"},
    {value: "FSI", label: "FSI"},
    {value: "URM Food Services", label: "URM Food Services"},
    {value: "CRS OneSource", label: "CRS OneSource"},
    {value: "Kuna Foodservice", label: "Kuna Foodservice"},
    {value: "Kohl Wholesale", label: "Kohl Wholesale"},
    {value: "Donahue Bros", label: "Donahue Bros"},
    {value: "Tankersley Foodservice", label: "Tankersley Foodservice"},
    {value: "Acme Paper & Supply Co Inc", label: "Acme Paper & Supply Co Inc"},
    {value: "Gourmet Foods International - Atlanta GA", label: "Gourmet Foods International - Atlanta GA"},
    {value: "Birite Foodservice", label: "Birite Foodservice"},
    {value: "Dairyland - The Chef's Warehouse", label: "Dairyland - The Chef's Warehouse"},
    {value: "Direct Supply", label: "Direct Supply"},
    {value: "Doerle Food Services", label: "Doerle Food Services"},
    {value: "Eastern Bag & Paper Co", label: "Eastern Bag & Paper Co"},
    {value: "Farner Bocken Carroll", label: "Farner Bocken Carroll"},
    {value: "Feesers Inc", label: "Feesers Inc"},
    {value: "Florida Food Service", label: "Florida Food Service"},
    {value: "Maplevale Farms Inc", label: "Maplevale Farms Inc"},
    {value: "Martin Brothers", label: "Martin Brothers"},
    {value: "Merchants Grocery Company Inc", label: "Merchants Grocery Company Inc"},
    {value: "Nicholas & Company", label: "Nicholas & Company"},
    {value: "Palmer Foodservice", label: "Palmer Foodservice"},
    {value: "Saladino's Inc", label: "Saladino's Inc"},
    {value: "Southeastern Paper Co Inc", label: "Southeastern Paper Co Inc"},
    {value: "Ginsbergs Inc", label: "Ginsbergs Inc"},
    {value: "Graves Menu Maker Foods", label: "Graves Menu Maker Foods"},
    {value: "Hill N Markes", label: "Hill N Markes"},
    {value: "Indianhead Foodservice", label: "Indianhead Foodservice"},
    {value: "Jacmar Foodservice Dist", label: "Jacmar Foodservice Dist"},
    {value: "Jordanos", label: "Jordanos"},
    {value: "Pocono ProFoods", label: "Pocono ProFoods"},
    {value: "Wabash Foodservice", label: "Wabash Foodservice"},
    {value: "Y Hata and Company", label: "Y Hata and Company"},
    {value: "Glacier Wholesalers Inc", label: "Glacier Wholesalers Inc"},
    {value: "Badger Wholesale - Green Bay, WI", label: "Badger Wholesale - Green Bay, WI"},
    {value: "Land & Sea - Monroeville, PA", label: "Land & Sea - Monroeville, PA"},
    {value: "John Gross - Mechanicsburg, PA", label: "John Gross - Mechanicsburg, PA"},
    {value: "Chambers & Owen, Inc.", label: "Chambers & Owen, Inc."},
    {value: "McAneny Brothers - Edensburg, PA", label: "McAneny Brothers - Edensburg, PA"},
    {value: "SGC Springfield Grocer - Springfield, MO", label: "SGC Springfield Grocer - Springfield, MO"},
    {value: "Northern Lights Foodservice - Fort Dodge, IA", label: "Northern Lights Foodservice - Fort Dodge, IA"},
    {value: "MJ Kellner - Springfield, IL", label: "MJ Kellner - Springfield, IL"},
    {value: "Johnston Paper", label: "Johnston Paper"},
    {value: "Midstate Bakery Distributors (Upstate)", label: "Midstate Bakery Distributors (Upstate)"},
    {value: "Essential Baking Company", label: "Essential Baking Company"},
    {value: "Canada Dry (PA)", label: "Canada Dry (PA)"},
    {value: "Pippin Wholesale Co", label: "Pippin Wholesale Co"},
    {value: "Tapia Brothers (PA)", label: "Tapia Brothers (PA)"},
    {value: "Hubert Company LLC", label: "Hubert Company LLC"},
    {value: "Custom Food Service - NT", label: "Custom Food Service - NT"},
    {value: "Shamrock Foodservice", label: "Shamrock Foodservice"},
    {value: "Evco Wholesale Food Corp", label: "Evco Wholesale Food Corp"},
    {value: "Western Paper", label: "Western Paper"},
    {value: "Affiliated Foods Inc", label: "Affiliated Foods Inc"},
    {value: "Hawaii Coffee Company (Paradise Beverages)", label: "Hawaii Coffee Company (Paradise Beverages)"},
    {value: "Tony's Fine Foods - NT", label: "Tony's Fine Foods - NT"},
    {value: "Supply America (PA) via Foodbuy", label: "Supply America (PA) via Foodbuy"},
    {value: "Sid Wainer & Son", label: "Sid Wainer & Son"},
    {value: "Dade Atlanta Broom Company", label: "Dade Atlanta Broom Company"},
    {value: "Penn Jersey Paper", label: "Penn Jersey Paper"},
    {value: "Vitco", label: "Vitco"},
    {value: "Coca-Cola (PA)", label: "Coca-Cola (PA)"},
    {value: "Dean Foods (PA)", label: "Dean Foods (PA)"},
    {value: "True World Foods", label: "True World Foods"},
    {value: "FoodPro", label: "FoodPro"},
    {value: "Dade Paper Company", label: "Dade Paper Company"},
    {value: "Gordon Foodservice", label: "Gordon Foodservice"},
    {value: "Admiral Linen & Uniform Service (PA)", label: "Admiral Linen & Uniform Service (PA)"},
    {value: "Driscoll Foods", label: "Driscoll Foods"},
    {value: "Balford Farms", label: "Balford Farms"},
    {value: "Benjamin Foods", label: "Benjamin Foods"},
    {value: "American Quick Foods", label: "American Quick Foods"},
    {value: "LA Specialty", label: "LA Specialty"},
    {value: "DiCarlo Foodservice", label: "DiCarlo Foodservice"},
    {value: "Van Eerden Distribution", label: "Van Eerden Distribution"},
    {value: "J Kings Food Service Professionals", label: "J Kings Food Service Professionals"},
    {value: "Upper Lakes Foods Inc.", label: "Upper Lakes Foods Inc."},
    {value: "Alsco Textile", label: "Alsco Textile"},
    {value: "Cash-Wa Distributing", label: "Cash-Wa Distributing"},
    {value: "Cheney Brothers Inc (PA)", label: "Cheney Brothers Inc (PA)"},
    {value: "Ecolab", label: "Ecolab"},
    {value: "FSA (PA)", label: "FSA (PA)"},
    {value: "Maines Paper and Foodservice", label: "Maines Paper and Foodservice"},
    {value: "Performance Food Group", label: "Performance Food Group"},
    {value: "Reinhart Foodservice", label: "Reinhart Foodservice"},
    {value: "Joshen Paper & Packaging", label: "Joshen Paper & Packaging"},
    {value: "Franks Quality Produce", label: "Franks Quality Produce"},
    {value: "Sound Produce - Seattle, WA", label: "Sound Produce - Seattle, WA"},
    {value: "Auto-Chlor System", label: "Auto-Chlor System"},
    {value: "Royal Cup Coffee - Albuquerque", label: "Royal Cup Coffee - Albuquerque"},
    {value: "Imperial Bag & Paper Company LLC (PA)", label: "Imperial Bag & Paper Company LLC (PA)"},
    {value: "Baldor Specialty Foods", label: "Baldor Specialty Foods"},
    {value: "Sutters Quality Foods", label: "Sutters Quality Foods"},
    {value: "New Cremflora Foods Inc", label: "New Cremflora Foods Inc"},
    {value: "Northern Haserot Co.", label: "Northern Haserot Co."},
    {value: "Ferraro Foods", label: "Ferraro Foods"},
    {value: "HPC Foodservice", label: "HPC Foodservice"},
    {value: "Sutherland's Food Service", label: "Sutherland's Food Service"},
    {value: "Lisanti Food Service", label: "Lisanti Food Service"},
    {value: "Kast Distributors", label: "Kast Distributors"},
    {value: "Instant Whip Dairy (Upstate)", label: "Instant Whip Dairy (Upstate)"},
    {value: "Thurston Foods Inc.", label: "Thurston Foods Inc."},
    {value: "Ace Endico Corp", label: "Ace Endico Corp"},
    {value: "Brown Foodservice Inc", label: "Brown Foodservice Inc"},
    {value: "Ettline Foods", label: "Ettline Foods"},
    {value: "Duplicate - Northern Haserot Co", label: "Duplicate - Northern Haserot Co"},
    {value: "Renzi Brothers Inc", label: "Renzi Brothers Inc"},
    {value: "Lipari Foods - Warren, MI", label: "Lipari Foods - Warren, MI"},
    {value: "Hillcrest Foods - Fairfax, VT", label: "Hillcrest Foods - Fairfax, VT"},
    {value: "Favorite Foods - Somersworth, NH", label: "Favorite Foods - Somersworth, NH"},
    {value: "Latina Boulevard Foods - Tonawanda, NY", label: "Latina Boulevard Foods - Tonawanda, NY"},
    {value: "RRS Foodservice", label: "RRS Foodservice"},
    {value: "Concord Foods Inc (PA)", label: "Concord Foods Inc (PA)"},
    {value: "Labatt Food Service (PA)", label: "Labatt Food Service (PA)"},
    {value: "P&R Paper", label: "P&R Paper"},
    {value: "ACC Distributors Inc", label: "ACC Distributors Inc"},
    {value: "Tripifoods Buffalo", label: "Tripifoods Buffalo"},
    {value: "Harbour Equipment Chelsea", label: "Harbour Equipment Chelsea"},
    {value: "Palmer Shelbyville", label: "Palmer Shelbyville"},
    {value: "Auto Chlor - Mountain View, CA (HQ)", label: "Auto Chlor - Mountain View, CA (HQ)"},
    {value: "US Foods", label: "US Foods"},
    {value: "Foods Galore", label: "Foods Galore"},
    {value: "Ben E Keith (PA)", label: "Ben E Keith (PA)"},
    {value: "Sysco", label: "Sysco"},
    {value: "Albert Uster Imports", label: "Albert Uster Imports"},
    {value: "Halpern's Steak and Seafood", label: "Halpern's Steak and Seafood"},
    {value: "Ambrogi", label: "Ambrogi"},
    {value: "Mansfield Paper", label: "Mansfield Paper"},
    {value: "Grainger", label: "Grainger"},
    {value: "Santa Maria - Shreveport, LA", label: "Santa Maria - Shreveport, LA"},
    {value: "Southern Glazer's Wine & Spirits - Miami, FL", label: "Southern Glazer's Wine & Spirits - Miami, FL"},
    {value: "B&G Rest Equipment & Supply - Pittsfield, MA", label: "B&G Rest Equipment & Supply - Pittsfield, MA"},
    {value: "Worldwide Produce - Las Vegas, NV", label: "Worldwide Produce - Las Vegas, NV"},
    {value: "151 Foods - Bellmawr, NJ", label: "151 Foods - Bellmawr, NJ"},
    {value: "Jack & Jill - Moorestown, NJ", label: "Jack & Jill - Moorestown, NJ"},
    {value: "A Esposito - Philadelphia, PA", label: "A Esposito - Philadelphia, PA"},
    {value: "Piazza Produce - Indianapolis, IN", label: "Piazza Produce - Indianapolis, IN"},
    {value: "Keany Produce", label: "Keany Produce"},
    {value: "Supreme Lobster and Seafood Co", label: "Supreme Lobster and Seafood Co"},
    {value: "Fortune Fish", label: "Fortune Fish"},
    {value: "MidWest Foods-Pro*Act", label: "MidWest Foods-Pro*Act"},
    {value: "Loffredo Fresh Unknown DC-Pro*Act", label: "Loffredo Fresh Unknown DC-Pro*Act"},
    {value: "S&D Coffee", label: "S&D Coffee"},
    {value: "American Hotel Register, (AHR)", label: "American Hotel Register, (AHR)"},
    {value: "Bunzl Distribution Inc", label: "Bunzl Distribution Inc"},
    {value: "Borden Dairy (PA)", label: "Borden Dairy (PA)"},
    {value: "Trimark (PA)", label: "Trimark (PA)"},
    {value: "Duplicate - Pepsi-Cola", label: "Duplicate - Pepsi-Cola"},
    {value: "Hardie's Fruit & Vegetables Austin-Pro*Act", label: "Hardie's Fruit & Vegetables Austin-Pro*Act"},
    {value: "City Line Distributors West Haven CT", label: "City Line Distributors West Haven CT"},
    {value: "Cintas", label: "Cintas"},
    {value: "New Carbon Company Inc - NT", label: "New Carbon Company Inc - NT"},
    {value: "Tropical Nut & Fruit (PA)", label: "Tropical Nut & Fruit (PA)"},
    {value: "Greco & Sons Inc", label: "Greco & Sons Inc"},
    {value: "Core-Mark (PA)", label: "Core-Mark (PA)"},
    {value: "Santa Monica Seafood", label: "Santa Monica Seafood"},
    {value: "Chef Works Inc", label: "Chef Works Inc"},
    {value: "Chudy Paper Company", label: "Chudy Paper Company"},
    {value: "Tama Trading Company", label: "Tama Trading Company"},
    {value: "Ted's Foodservice", label: "Ted's Foodservice"},
    {value: "J&D Foods", label: "J&D Foods"},
    {value: "S&W Wholesale Foods, LLC", label: "S&W Wholesale Foods, LLC"},
    {value: "Marques Food Distributors", label: "Marques Food Distributors"},
    {value: "Hill & Markes - Amsterdam, NY", label: "Hill & Markes - Amsterdam, NY"},
    {value: "Challenge Dairy Products - CA (PA)", label: "Challenge Dairy Products - CA (PA)"},
    {value: "DPI (PA)", label: "DPI (PA)"},
    {value: "Economical Janitorial & Paper Supplies", label: "Economical Janitorial & Paper Supplies"},
    {value: "F Christiana & Co", label: "F Christiana & Co"},
    {value: "CD Hartnett", label: "CD Hartnett"},
    {value: "Schneider's Premium Seafood - Cheektowaga, NY", label: "Schneider's Premium Seafood - Cheektowaga, NY"},
    {value: "Ray's Wholesale Meat Inc", label: "Ray's Wholesale Meat Inc"},
    {value: "Keck's Foodservice", label: "Keck's Foodservice"},
    {value: "Schneider Paper Products", label: "Schneider Paper Products"},
    {value: "Desert Gold Food Co Inc", label: "Desert Gold Food Co Inc"},
    {value: "Creation Gardens", label: "Creation Gardens"},
    {value: "Seashore Food Distributors INC.", label: "Seashore Food Distributors INC."},
    {value: "Anheuser-Busch", label: "Anheuser-Busch"},
    {value: "Republic National Dist Co", label: "Republic National Dist Co"},
    {value: "Staples", label: "Staples"},
    {value: "Shaheen Brothers Inc - NT", label: "Shaheen Brothers Inc - NT"},
    {value: "B&B Foods - Terre Haute IN", label: "B&B Foods - Terre Haute IN"},
    {value: "Maple Leaf Foods - NT", label: "Maple Leaf Foods - NT"},
    {value: "Office Max/Office Depot", label: "Office Max/Office Depot"},
    {value: "Mid-AMerica Wholesale - Enid, OK", label: "Mid-AMerica Wholesale - Enid, OK"},
    {value: "Spec's - Houston, TX", label: "Spec's - Houston, TX"},
    {value: "Food Supply - South Daytona, FL", label: "Food Supply - South Daytona, FL"},
    {value: "Franz Family Bakeries - Portland, OR", label: "Franz Family Bakeries - Portland, OR"},
    {value: "Pain D'Avignon - Hyannis, MA", label: "Pain D'Avignon - Hyannis, MA"},
    {value: "Calvada Food Sales - Reno, NV", label: "Calvada Food Sales - Reno, NV"},
    {value: "Sierra Gold Seafood - Sparks, NV", label: "Sierra Gold Seafood - Sparks, NV"},
    {value: "Brady Industries - Las Vegas, NV", label: "Brady Industries - Las Vegas, NV"},
    {value: "At Work Uniforms - Orange Beach, AL", label: "At Work Uniforms - Orange Beach, AL"},
    {value: "Premier Produce - Dania Beach, FL", label: "Premier Produce - Dania Beach, FL"},
    {value: "Snyder's-Lance - Charlotte, NC", label: "Snyder's-Lance - Charlotte, NC"},
    {value: "Rave Associates - Ann Arbor, MI", label: "Rave Associates - Ann Arbor, MI"},
    {value: "Sierra Meat and Seafood - Marina, CA", label: "Sierra Meat and Seafood - Marina, CA"},
    {value: "Office Depot - Headquarters, FL", label: "Office Depot - Headquarters, FL"},
    {value: "National Meat & Provisions - Reserve, LA", label: "National Meat & Provisions - Reserve, LA"},
    {value: "Sirna And Sons - East Norwalk, OH", label: "Sirna And Sons - East Norwalk, OH"},
    {value: "Chef Source - Canton, MI", label: "Chef Source - Canton, MI"},
    {value: "Imperial Beverage - Kalamazoo, MI", label: "Imperial Beverage - Kalamazoo, MI"},
    {value: "Courtesy Products - Bridgeton, MO", label: "Courtesy Products - Bridgeton, MO"},
    {value: "Masada Bakery", label: "Masada Bakery"},
    {value: "Paul Marks Dairy", label: "Paul Marks Dairy"},
    {value: "Royal Food Service Produce", label: "Royal Food Service Produce"},
    {value: "Downtown Produce", label: "Downtown Produce"},
    {value: "Duck Delivery Produce", label: "Duck Delivery Produce"},
    {value: "Perkins Paper", label: "Perkins Paper"},
    {value: "BK Specialty Food Inc.", label: "BK Specialty Food Inc."},
    {value: "Charlie's Produce Seatlle WA-Pro*Act", label: "Charlie's Produce Seatlle WA-Pro*Act"},
    {value: "Seashore Fruit & Produce-Pro*Act", label: "Seashore Fruit & Produce-Pro*Act"},
    {value: "McLane Company Inc - NT", label: "McLane Company Inc - NT"},
    {value: "Maximum Quality Foods - NT", label: "Maximum Quality Foods - NT"},
    {value: "Shoes For Crews LLC", label: "Shoes For Crews LLC"},
    {value: "J Polep Distribution Services", label: "J Polep Distribution Services"},
    {value: "Stanz Foodservice", label: "Stanz Foodservice"},
    {value: "Hershey Creamery Company (PA)", label: "Hershey Creamery Company (PA)"},
    {value: "New England Ice Cream (PA)", label: "New England Ice Cream (PA)"},
    {value: "Stern Produce-Pro*Act", label: "Stern Produce-Pro*Act"},
    {value: "Russell Hall Company, Inc. - (SMA", label: "Russell Hall Company, Inc. - (SMA"},
    {value: "Meats by Linz", label: "Meats by Linz"},
    {value: "Houston Avocado Company", label: "Houston Avocado Company"},
    {value: "Red Bull North America (PA)", label: "Red Bull North America (PA)"},
    {value: "BreakThru Beverage", label: "BreakThru Beverage"},
    {value: "A New Dairy Inc", label: "A New Dairy Inc"},
    {value: "Edward Don & Co (PA)", label: "Edward Don & Co (PA)"},
    {value: "Leonard Paper Company - (SMA", label: "Leonard Paper Company - (SMA"},
    {value: "Better Brand Foods", label: "Better Brand Foods"},
    {value: "W. B Mason", label: "W. B Mason"},
    {value: "Merlino Foods", label: "Merlino Foods"},
    {value: "MacDonald Meat - NT", label: "MacDonald Meat - NT"},
    {value: "McFarling Foods", label: "McFarling Foods"},
    {value: "Merchants Foodservice", label: "Merchants Foodservice"},
    {value: "South Holland Paper", label: "South Holland Paper"},
    {value: "Wood Fruitticher Grocery", label: "Wood Fruitticher Grocery"},
    {value: "Associated Wholesale Grocers (AWG)", label: "Associated Wholesale Grocers (AWG)"},
    {value: "Sofo Foods (PA)", label: "Sofo Foods (PA)"},
    {value: "NW Foodservice Distribution", label: "NW Foodservice Distribution"},
    {value: "HumRun Repair Dog LLC", label: "HumRun Repair Dog LLC"},
    {value: "RingCentral", label: "RingCentral"},
    {value: "Airgas", label: "Airgas"},
    {value: "Comprehensive Hospitality Solutions", label: "Comprehensive Hospitality Solutions"},
    {value: "FattMerchant", label: "FattMerchant"},
    {value: "Skechers", label: "Skechers"},
    {value: "Sprint", label: "Sprint"},
    {value: "DIRECTTV", label: "DIRECTTV"},
    {value: "Lavu Incorporated", label: "Lavu Incorporated"},
    {value: "Cozzini Bros Inc", label: "Cozzini Bros Inc"},
    {value: "Tipzyy", label: "Tipzyy"},
    {value: "Mood Media", label: "Mood Media"},
    {value: "Foodservice Training Portal, LLC", label: "Foodservice Training Portal, LLC"},
    {value: "Aramark", label: "Aramark"},
    {value: "PlateIQ", label: "PlateIQ"},
    {value: "Atlantic Food Distributors", label: "Atlantic Food Distributors"},
    {value: "Intercounty Bakers", label: "Intercounty Bakers"},
    {value: "Tankersley Foodservice", label: "Tankersley Foodservice"},
    {value: "Supreme Paper Supplies", label: "Supreme Paper Supplies"},
    {value: "Holt Paper Co. Inc", label: "Holt Paper Co. Inc"},
    {value: "Majestic Food Service", label: "Majestic Food Service"},
    {value: "Prince Paper & Twine Company", label: "Prince Paper & Twine Company"},
    {value: "Reddy Raw Inc", label: "Reddy Raw Inc"},
    {value: "A. Oliveri & Sons", label: "A. Oliveri & Sons"},
    {value: "H Schrier & Co Inc", label: "H Schrier & Co Inc"},
    {value: "Price Paper & Twine Company", label: "Price Paper & Twine Company"},
    {value: "C&J Quality Distributors", label: "C&J Quality Distributors"},
    {value: "Motor City Supplies", label: "Motor City Supplies"},
    {value: "Sherwood Food Distributors", label: "Sherwood Food Distributors"},
    {value: "Testa Produce", label: "Testa Produce"},
    {value: "M & L Foods", label: "M & L Foods"},
    {value: "Barboy Products In", label: "Barboy Products In"},
    {value: "Matera Paper Company", label: "Matera Paper Company"},
    {value: "World Centric", label: "World Centric"},
    {value: "Greenleaf", label: "Greenleaf"},
    {value: "Individual FoodService", label: "Individual FoodService"},
    {value: "Allen Paper Supply", label: "Allen Paper Supply"},
    {value: "Saval", label: "Saval"},
    {value: "International Gourmet Foods Incorporated - NT", label: "International Gourmet Foods Incorporated - NT"},
    {value: "H & M Wagner & Sons, Inc.", label: "H & M Wagner & Sons, Inc."},
    {value: "Jake's Finer Foods", label: "Jake's Finer Foods"},
    {value: "Sea Breeze Food Service", label: "Sea Breeze Food Service"},
    {value: "Tripifoods Erie", label: "Tripifoods Erie"},
    {value: "National DCP", label: "National DCP"},
    {value: "FreshPoint", label: "FreshPoint"},
    {value: "Integrative Flavors", label: "Integrative Flavors"},
    {value: "Golden West Food Group", label: "Golden West Food Group"}
];