import React from 'react';
import './App.scss';
import Form from '../Views/Form/Form';
import Container from './Container';

function App() {
  return (
    <Container>
      <Form />
    </Container>
  );
}

export default App;
