import React from 'react';
import './Input.scss';

function InputTitle (props) {
  const { title } = props;

  const className= props.active ? 'vault-label active' : 'vault-label';

  return (
    <label className={className}> { title } </label>
  )
}

export default InputTitle;