import React, { useState } from 'react';
import FormContent from './FormContent';
import axios from 'axios';
import validate from 'validate.js';

function Form() {
  const [email, setEmail] = useState('');
  const [provideInformation, setProvideInformation] = useState('');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [distributorName, setDistributorName] = useState(null);
  const [dcn, setDcn] = useState('');
  const [distributorUrl, setDistributorUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({noerror: true});
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  
  const onSubmit = () => {
    const constrains = {
      email: {
        presence: true,
        email: true,
      },
      fullName: {
        presence: true,
        length: {
          minimum: 3
        }
      },
      companyName: {
        presence: true,
        length: {
          minimum: 3
        }
      },
      // dcn: {
      //   presence: true,
      //   length: {
      //     minimum: 3
      //   }
      // },
      username: {
        presence: true,
      },
      password: {
        presence: true,
      },
      // provideInformation: {
        // presence: true,
      // },
      distributorName: {
        presence: true,
      },
      distributorUrl: {
        presence: true,
        url: true
      }
    };

    const data = {
      email: email,
      fullName: name,
      provideInformation: provideInformation ? provideInformation.label : null,
      companyName: companyName,
      distributorName: distributorName ? distributorName.label : null,
      dcn: dcn,
      distributorUrl: distributorUrl,
      username: username,
      password: password
    };

    const result = validate(data, constrains);
    if (result === undefined) {
      sendToServer(data);
      setError({noerror:true});
    } else {
      setError(result);
    }
  };

  const sendToServer = (data) => {
    setLoading(true);
    axios.post("/api/transfer", data).then(({data})=>{
      setLoading(false);
      if (data === "success") {
        setDone(true);
      }
    });
  };

  const cleanError = (field) => {
    let newError = {...error};
    if (newError[field]) {
      delete newError[field];
      setError(newError);
    }
  }

  const onAnother = () => {
    setEmail('');
    setName('');
    setCompanyName('');
    setProvideInformation(null);
    setDcn('');
    setDistributorName(null);
    setDistributorUrl('');
    setUsername('');
    setPassword('');
    setDone(false);
  }

  const passInProps = {
    email, setEmail, name, setName, companyName, setCompanyName,
    provideInformation, setProvideInformation, error, dcn, setDcn,
    distributorName, setDistributorName, distributorUrl, setDistributorUrl,
    username, setUsername, password, setPassword, onSubmit, loading, done, onAnother, cleanError
  }

  return (
    <FormContent {...passInProps} />
  )
}

export default Form;
