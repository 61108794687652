import React from 'react';
import VaultLogo from '../../../assets/vault-logo.svg';
import HashicorpLogo from '../../../assets/hashicorp-logo.svg';

function FormLogos() {
  return (
    <div className='logos'>
      <img src={VaultLogo} alt='vault' />
      <img src={HashicorpLogo} alt='hashicorp' />
    </div>
  )
}

export default FormLogos;