import React from 'react'

function Container (props) {
  const { children } = props;
  return (
    <div className='bg'>
      <div className='header'></div>
      <main className='form'>
        {children}
      </main>
    </div>
  )
}

export default Container;