import React from 'react';

function FormInstructions() {
  return (
    <p>
      Please provide the information that is requested below which
      will be used to expedite the processing of your data that is
      used for rebate processing. If you have separate logins for
      each location or if you have multiple distributors that we
      need to pull data from, please complete one form per
      distributor and/or login.
    </p>
  )
}

export default FormInstructions;