import React, { useState } from 'react';
import Input from './Input';
import InputTitle from './InputTitle';
import './Input.scss';

function InputForm(props) {
  const { title, set, value, type, placeholder, error, cleanError } = props;
  const [active, setActive] = useState(false)

  const onFocus = () => {
    setActive(true);
    cleanError();
  };

  const onBlur = () => {
    setActive(false);
    if (type === 'url') {
      let url = value.trim();
      if (url.search('http://') === 0 || url.search('https://') === 0) {
        set(url);
      } else {
        set('https://'+url);
      }
    }
  };

  return (
    <div className="input-form">
      <InputTitle title={title} active={active} />
      <Input set={set} value={value}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      <span className="error">{error && error[0]}</span>
    </div>
  );
}

export default InputForm;