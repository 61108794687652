import React from 'react';
import distributors from '../../Config/distributors';
// import provideInformationOptions from '../../Config/provideInformationOptions';
import FormInstructions from './Components/FormInstructions';
// import ContactInfo from '../../MasterComponents/ContactInfo/ContactInfo';
import InputForm from '../../MasterComponents/InputForm/InputForm';
import InputDropdown from '../../MasterComponents/InputForm/InputDropdown';
import Button from '../../MasterComponents/Button/Button';
import FormLogos from './Components/FormLogos';

function FormContent(props) {
  const { email, setEmail,
    name, setName,
    // provideInformation, setProvideInformation,
    companyName,setCompanyName,
    distributorName, setDistributorName,
    dcn, setDcn,
    distributorUrl, setDistributorUrl,
    username, setUsername,
    password, setPassword,
    onSubmit, error, loading, done,
    onAnother, cleanError
  } = props;

  const title = 'Vault Secure Transfer';
  
  if (done) {
    return (
      <div>
        <h2>{title}</h2>
        <FormLogos />
        <p>That's it! You are all done.</p>
        <Button text="Submit Another" onClick={onAnother} />
      </div>
    );
  }

  return (
    <div>
      <h2>{title}</h2>
      <FormLogos />
      <FormInstructions />
      {/* <ContactInfo name="Jason Fialkoff" email="jason.fialkoff@buyersedgeplatform.com" phone="321.696.2219" /> */}
      {/* <ContactInfo name="Jason Doren" email="jason.doren@buyersedgeplatform.com" phone="815.603.2289" /> */}
      <InputForm cleanError={ () => cleanError('email') } error={error.email} value={email} set={setEmail} title="Email Address" type="email" placeholder="Your answer" />
      {/* <InputDropdown error={error.provideInformation} isSearchable={false} options={provideInformationOptions} value={provideInformation} set={setProvideInformation} title="How do you wish to provide the requested information? *" /> */}
      <InputForm cleanError={ () => cleanError('fullName') } error={error.fullName} value={name} set={setName} title="Your Name" type="text" placeholder="Your answer" />
      <InputForm cleanError={ () => cleanError('companyName') } error={error.companyName} value={companyName} set={setCompanyName} title="Your Company Name" type="text" placeholder="Your answer" />
      <InputDropdown cleanError={ () => cleanError('distributorName') } error={error.distributorName} isSearchable={true} options={distributors} value={distributorName} set={setDistributorName} title="What is the name of your distributor? (one distributor per form)" />
      {/*<InputForm cleanError={ () => cleanError('dnc') } error={error.dcn} value={dcn} set={setDcn} title="What is your DCN?" type="text" placeholder="Your answer" />*/}
      <InputForm cleanError={ () => cleanError('distributorUrl') } error={error.distributorUrl} value={distributorUrl} set={setDistributorUrl} title="What is the url used to log into your distributor portal?" type="url" placeholder="https://domain.com" />
      <InputForm cleanError={ () => cleanError('username') } error={error.username} value={username} set={setUsername} title="What is your username?" type="text" placeholder="Your answer" />
      <InputForm cleanError={ () => cleanError('password') } error={error.password} value={password} set={setPassword} title="What is your password?" type="password" placeholder="Your answer" />
      <Button disabled={loading} text={ loading ? "Loading..." : "Submit"} onClick={onSubmit} onSubmit={onSubmit} />
    </div>
  )
}

export default FormContent;
