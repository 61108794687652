import React from 'react';
import './Button.scss';

function Button (props) {
  const { text, onClick, disabled } = props;
  return (
    <button
      type="submit"
      onSubmit={onClick}
      disabled={disabled}
      className="vault-button"
      onClick={onClick}
    >
      {text}
    </button>
  )
}

export default Button;