import React, { useState } from 'react';
import InputTitle from './InputTitle';
import Select from 'react-select';
import './Input.scss';

function InputDropdown(props) {
  const { title, value, isSearchable, error, cleanError } = props;
  const [active, setActive] = useState(false);
  const [options, setOptions] = useState(props.options);

  const onChange = (value) => {
    const { set } = props;
    set({
      label: value.value,
      value: value.value
    });
  };

  const onFocus = () => {
    setActive(true);
    cleanError();
  };

  const onBlur = () => {
    setActive(false);
  };

  const addNewOption = (str) => {
    if (str) {
      setOptions([
        {value: str, label: `Other: ${str}`},
        ...props.options
      ]);
    } else {
      setOptions(props.options);
    }
  };

  return (
	  <div className="input-form">
      <InputTitle title={title} active={active} />
      <Select className='vault-select' classNamePrefix='vault'
        onFocus={onFocus}
        onBlur={onBlur}
        value={value} options={options}
        onInputChange={addNewOption}
        isSearchable={isSearchable}
        onChange={onChange}
        placeholder={ isSearchable ? "Type to Search" : "Choose"}
      />
      <span className="error">{error && error[0]}</span>
	  </div>
  );
}

export default InputDropdown;